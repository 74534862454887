import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

const SUB_NAV_ITEM_SELECTOR = '.sub-nav__item';
const SUB_NAV_LINK_SELECTOR = '.sub-nav__link';
const ACTIVE_CLASS = 'sub-nav__item--active';
const SBU_ID = 'sbuId';

const handleTabClick = ($subNavLink, $cardsContainer, $subNavItems) => {
    const $subNavItem = $subNavLink.closest(SUB_NAV_ITEM_SELECTOR);
    const sbuData = $subNavLink.data(SBU_ID);
    const sbuId = sbuData ? String(sbuData) : false;
    $subNavItems.removeClass(ACTIVE_CLASS);
    $subNavItem.addClass(ACTIVE_CLASS);
    const $contentColumns = $cardsContainer.find('.content-column');
    if (sbuId) {
        $contentColumns.hide();
        $contentColumns
            .filter((i, el) => String($(el).data(SBU_ID)).split(',').includes(sbuId))
            .show();
    } else {
        $contentColumns.show();
    }

    doAction('updateScrollTrigger');
};

const initOurTeamTabs = () => {
    const $tabsContainer = $('.our-team-tabs');
    if (!$tabsContainer.length) {
        return;
    }

    const $cardsContainer = $('.our-team-member-card-row');
    const $subNavItems = $tabsContainer.find(SUB_NAV_ITEM_SELECTOR);
    $subNavItems.first().addClass(ACTIVE_CLASS);
    $tabsContainer.on('click', SUB_NAV_LINK_SELECTOR, (e) => {
        e.preventDefault();
        handleTabClick($(e.target), $cardsContainer, $subNavItems);
    });
};

addAction(INIT, initOurTeamTabs);
