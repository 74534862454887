import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $caseStudyCards = $('.case-study-card');
    $caseStudyCards.each((index, value) => {
        const $caseStudyCard = $(value);
        const $svg = $caseStudyCard.find('.case-study-card__affiliated-sbu .icon');
        const $iconStudio = $('#icon-studio');
        const $iconTownHall = $('#icon-town-hall');
        if ($svg.length && $svg.hasClass('icon-studio') && !$iconStudio.hasClass('highlight')) {
            $iconStudio.find('.apply-highlight').addClass('highlight');
        }
        if (
            $svg.length
            && $svg.hasClass('icon-town-hall')
            && !$iconTownHall.hasClass('highlight')
        ) {
            $iconTownHall.find('.apply-highlight').addClass('highlight');
        }
    });
});
