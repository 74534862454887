import { $, addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (settings, $carousel) => ({
    ...settings,
    items: 1,
    stagePadding: 0,
    navContainer: $('<div class="content-slider__arrows"></div>').insertAfter($carousel),
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow-right"></use></svg>',
    ],
}));
