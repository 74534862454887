import {
    $, addAction, doAction, INIT, LAYOUTEND,
} from '@situation/setdesign.util';

let isInitialized = false;
const owlSettings = {
    margin: 0,
    items: 1.2,
    dots: false,
    nav: false,
    center: true,
    loop: true,
    responsive: {
        478: { items: 1.5 },
        576: { items: 2, center: true },
        768: {
            items: 3, loop: false, center: false, stagePadding: 10,
        },
        992: {
            items: 4, loop: false, center: false, stagePadding: 10,
        },
    },
};

const wrapSbuCards = () => {
    $('.sbu-card')
        .filter((i, el) => !$(el).prev().hasClass('sbu-card'))
        .each((i, el) => {
            const $group = $(el).nextUntil(':not(.sbu-card)').addBack();
            if ($group.length > 1) {
                $group.wrapAll('<div class="sbu-card-row"></div>');
            }
        });

    const $cardRows = $('.sbu-card-row');

    const updateSlider = () => {
        if (SetDesign.viewport.width() < 992 && !isInitialized) {
            isInitialized = true;
            $cardRows.addClass('owl-carousel').owlCarousel(owlSettings);
        } else if (SetDesign.viewport.width() >= 992 && isInitialized) {
            isInitialized = false;
            $cardRows.removeClass('owl-carousel').trigger('destroy.owl.carousel');
        }
    };

    const refreshLazyLoad = (e) => {
        if (e.property.name === 'position') {
            doAction('refreshLazyLoad', $(e.currentTarget));
        }
    };

    $cardRows.on('changed.owl.carousel', refreshLazyLoad);
    updateSlider();
    addAction(LAYOUTEND, updateSlider);
};

addAction(INIT, wrapSbuCards);
